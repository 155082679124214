import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Menu, Icon, Badge, Dropdown, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'debounce';

import { toggleSidebar } from '@features/menuSlice';
import RenderLink from '@atoms/RenderLink/RenderLink';
import Logo from '@atoms/logo';
import getDefaultKeys from '@common/helps/defaultKeys';
import MessageNavigator from '@molecules/messages/MessageNavigator';
import MenuMobile from './menu/mobile';

import defaults from 'DEFAULTS';
import { tConsume } from 'TRANSLATION';

const Sidebar = ({ route, userType, t }) => {
  const [burgerOpened, setBurgerOpened] = useState(false);
  const { collapsed, menuConfig, device } = useSelector((state) => state.menu);
  const vendors = useSelector((state) => state.userToken.vendors);
  const unreadMessages = useSelector((state) => state.cases.unread);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const debouncedToggleCollapse = debounce(() => toggleCollapsed(), 200);
    toggleCollapsed();

    window.addEventListener('resize', debouncedToggleCollapse);

    return () => {
      window.removeEventListener('resize', debouncedToggleCollapse);
    };
  }, [burgerOpened]);

  const toggleBurger = () => setBurgerOpened(!burgerOpened);

  const toggleCollapsed = (byButton) => {
    const device = defaults.isDevice();
    const isPhone = ['phone-horizontal', 'phone'].indexOf(device) >= 0;
    const isDesktop = device === 'desktop';

    if (!isPhone && burgerOpened) {
      toggleBurger();
    }

    let _collapsed = !collapsed;

    if (!byButton) {
      _collapsed = !isDesktop;
    }

    dispatch(toggleSidebar({ collapsed: _collapsed, device }));
  };

  const renderItem = (item, top) => {
    const { active, icon, name, subItems, hidden, path, vendor } = item;

    if (!active || hidden || (vendor && vendors && vendors.indexOf(vendor) < 0)) {
      return;
    }

    const key = `${item.name} - ${item.path ? item.path : ''}`;
    const _name = t(`menu.${name}`);

    if ('subItems' in item) {
      const inner = (
        <>
          {icon && <Icon type={icon || 'border'} />}
          <span>{_name}</span>
        </>
      );

      const title =
        path && !subItems ? (
          <NavLink to={defaults.routing.getRoute(path, route)}>{inner}</NavLink>
        ) : (
          <span>{inner}</span>
        );

      return (
        <Menu.SubMenu key={key} title={title}>
          {renderMenu(subItems)}
        </Menu.SubMenu>
      );
    }

    const props = {
      key,
      ...(top !== undefined ? { title: _name } : {}),
    };

    return (
      <Menu.Item {...props}>
        <RenderLink item={item} userType={userType} route={route} />
      </Menu.Item>
    );
  };

  const renderMenu = (items) => {
    return items.map((item) => renderItem(item));
  };

  const menuTop = menuConfig.top ? menuConfig.top.map(renderItem) : [];
  const menuBottom = menuConfig.bottom ? menuConfig.bottom.map(renderItem) : [];
  const { defaultOpenKeys, defaultSelectedKeys } = getDefaultKeys(menuConfig, location);
  const isPhone = ['phone-horizontal', 'phone'].indexOf(device) >= 0;

  return (
    <>
      <nav className={`dashboard-wrapper-menu ${burgerOpened || !collapsed ? 'open' : ''}`}>
        <div className="nav-top">
          <div className={'align-center'}>
            <Link to={route}>
              <Logo height={isPhone || collapsed ? 40 : 50} small={collapsed} />
            </Link>
          </div>

          {isPhone ? (
            <div className="burger-wrap">
              {userType !== 'aemi' && (
                <Dropdown
                  trigger={['click']}
                  placement="bottomRight"
                  overlay={<MessageNavigator route={route} />}
                >
                  <Button type="link" size="large" className={'messages'}>
                    <Badge count={unreadMessages.count}>
                      <Icon type="mail" />
                    </Badge>
                  </Button>
                </Dropdown>
              )}

              <div className="burger" onClick={toggleBurger}>
                <div />
                <div />
                <div />
              </div>
            </div>
          ) : (
            <Menu
              mode="inline"
              inlineCollapsed={collapsed}
              style={{ borderRight: 'none' }}
              defaultOpenKeys={defaultOpenKeys}
              defaultSelectedKeys={defaultSelectedKeys}
            >
              {menuTop}
            </Menu>
          )}
        </div>

        {!isPhone && (
          <div className="nav-bottom">
            <a className="collapse-btn" onClick={() => toggleCollapsed(true)}>
              <Icon type={collapsed ? 'double-right' : 'double-left'} />
              {!collapsed && <span>{t('menu.collapse')}</span>}
            </a>
          </div>
        )}
      </nav>

      <MenuMobile
        visible={burgerOpened}
        defaultOpenKeys={defaultOpenKeys}
        defaultSelectedKeys={defaultSelectedKeys}
        menuItems={[...menuTop, ...menuBottom]}
        onClose={toggleBurger}
      />
    </>
  );
};

export default tConsume(Sidebar);

Sidebar.propTypes = {
  userType: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};
