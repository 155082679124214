import defaults from 'DEFAULTS';

export default {
  default: {
    name: 'Dashboard',
  },
  top: [
    {
      name: 'customers',
      path: 'customers',
      icon: 'shop',
      active: true,
      subItems: [
        {
          name: 'company',
          path: 'customers/company',
          permissions: ['customers.view'],
          active: defaults.variables.account_types.includes('company'),
          icon: 'cluster',
        },
        {
          name: 'individual',
          path: 'customers/individual',
          permissions: ['customers.view'],
          active: defaults.variables.account_types.includes('individual'),
          icon: 'user',
        },
      ],
    },
    {
      name: 'reports',
      path: 'reports',
      icon: 'filter',
      active: true,
      permissions: ['transactions.view'],
    },
    {
      name: 'popups',
      path: 'popups',
      icon: 'alert',
      active: true,
      permissions: ['transactions.view'],
    },
    {
      name: 'payment_code',
      path: 'payment-code',
      icon: 'barcode',
      active: true,
      permissions: ['transactions.view'],
    },
    {
      name: 'aml',
      path: 'aml',
      icon: 'border-verticle',
      active: true,
      permissions: [
        'aml.AML.determine',
        'aml.CRO.determine',
        'aml.MLRO.determine',
        'aml.SPECTATOR.determine',
      ],
    },
    {
      name: 'banks',
      path: 'bank',
      icon: 'bank',
      active: true,
      permissions: ['bank_institution.show'],
    },
    {
      name: 'treasury',
      path: 'treasury',
      icon: 'audit',
      active: true,
      permissions: [
        'treasury.determine',
        'head_of_treasury.determine',
        'spectator_treasury.determine',
        'treasury_archive.view',
      ],
      subItems: [
        {
          name: 'transactions',
          path: 'treasury',
          icon: 'audit',
          permissions: [
            'treasury.determine',
            'head_of_treasury.determine',
            'spectator_treasury.determine',
          ],
          active: true,
        },
        {
          name: 'confirmations',
          path: 'confirmations',
          icon: 'file-protect',
          permissions: [
            'treasury.determine',
            'head_of_treasury.determine',
            'spectator_treasury.determine',
          ],
          active: true,
        },
        {
          name: 'archive',
          path: 'archive',
          icon: 'save',
          permissions: [
            'treasury.determine',
            'head_of_treasury.determine',
            'spectator_treasury.determine',
            'treasury_archive.view',
          ],
          active: true,
        },
      ],
    },
    {
      name: 'cases',
      path: 'cases',
      icon: 'issues-close',
      active: true,
      permissions: ['cases.view'],
    },
    {
      name: 'transactions',
      path: 'transactions',
      icon: 'swap',
      active: true,
      permissions: ['transactions.view'],
    },
    {
      name: 'wallets',
      path: 'wallets',
      icon: 'wallet',
      active: !defaults.variables.external_payments,
      subItems: [
        {
          name: 'accounts',
          path: 'wallets/accounts',
          permissions: ['wallets.view'],
          active: false,
          icon: 'bank',
        },
        {
          name: 'vaults',
          path: 'wallets/vaults',
          permissions: ['wallets.view'],
          active: !defaults.variables.external_payments,
          icon: 'credit-card',
        },
      ],
    },
    {
      name: 'pricing_plans',
      path: 'pricing',
      icon: 'euro',
      active: true,
      permissions: ['pricing_plans.view'],
      // subItems: [
      //   {
      //     name: 'active_plans',
      //     path: 'pricing/active',
      //     active: true,
      //     icon: 'play-circle',
      //   },
      //   {
      //     name: 'inactive_plans',
      //     path: 'pricing/inactive',
      //     active: true,
      //     icon: 'pause-circle',
      //   },
      //   {
      //     name: 'management',
      //     path: 'pricing/management',
      //     active: true,
      //     icon: 'solution',
      //     subItems: [
      //       {
      //         name: 'individual',
      //         path: 'pricing/management/individual',
      //         active: defaults.variables.account_types.includes('individual'),
      //         icon: 'user',
      //       },
      //       {
      //         name: 'company',
      //         path: 'pricing/management/company',
      //         active: defaults.variables.account_types.includes('company'),
      //         icon: 'cluster',
      //       },
      //     ],
      //   },
      // ],
    },
    {
      name: 'statistics',
      path: 'statistics',
      icon: 'line-chart',
      active: true,
      subItems: [
        {
          name: 'vendor_usage',
          path: 'statistics/vendor-usage',
          active: true,
          permissions: ['statistics.view'],
        },
      ],
    },
    {
      name: 'acl',
      path: 'control',
      icon: 'team',
      active: true,
      subItems: [
        {
          name: 'team',
          path: 'control/team',
          active: true,
          permissions: ['acl.team.users.view'],
        },
        {
          name: 'roles',
          path: 'control/roles',
          active: true,
          permissions: ['acl.team.roles.view'],
        },
        {
          name: 'permissions',
          path: 'control/permissions',
          active: true,
          permissions: ['acl.team.roles.view'],
        },
      ],
    },
  ],
  bottom: [
    {
      name: 'personal_profile',
      icon: 'user',
      active: true,
      path: 'profile',
    },
    {
      name: 'logout',
      exit: true,
      icon: 'logout',
      active: true,
    },
  ],
};
